import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Trans, t } from '@lingui/macro';
import format from 'date-fns/format';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  FormControl,
  FormLabel,
  Tooltip,
} from '@mui/material';

import { search, SearchCriteria } from './model';
import { parseCriteriaParamsOut } from './utils';

import Tags from './Tags';
import { SearchTreeInput } from './SearchTreeInput';
import { ABSOLUTE_FOLDER_TAG_ID } from './constants';
import { useSearchTarget } from '../common/utils';
import { objectMap } from '~utils/fn.utils';
import SearchField from '~misc/SearchField';
import Error from '~misc/Error';
import { useActions, useKeyboardEvent, usePrevious } from '~utils/hooks.utils';
import Button from '~inputs/Button';
import CheckBoxes from '~inputs/CheckBoxes';
import Column from '~layout/Column';
import ButtonBar from '~sections/ButtonBar';
import {
  getLangValue,
  getLocalizedValue,
  getContentLanguage,
} from '~common/app.utils';
import {
  /* components using these commented out. Need to be uncommented if adding any of the commented filter fields
  materialTypeSearch,
  entryTypeSearch,
  fileTypeSearch,
  langSearch,
  */
  mimeGroupsSearch,
  materialTypesSearch,
} from '~common/content.constants';
import FormRow from '~common/inputs/FormRow';
import CustomFormLabel from '~common/inputs/FormLabel';
import FormField from '~common/inputs/FormField';
import {
  isDropdownMetaField,
  isTreeMetaField,
  MetaField,
} from '~common/content.types';
import { useGetFolderTreeQuery } from '~common/content.api';
import { TreePosition } from '~common/app.types';
import {
  updateContentCriteria,
  useCustomerMetaFields,
} from '~common/content.utils';
import { ExpandMoreIcon, MoreVertIcon } from '~common/misc/icons';
import { Typography } from '~common/misc/Typography';
import MenuButton from '~common/navigation/MenuButton';

interface Props {
  inModal?: boolean;
  onSearch?: () => void;
}

function getMetaFieldCriteriaId(
  metaField: MetaField,
  language: string
): `f-meta-${number}_${string}` {
  if (isDropdownMetaField(metaField)) {
    return `f-meta-${Number(metaField.id)}_${
      metaField.valueType.includes('multiple') ? 'multiple' : 'dropdown'
    }`;
  }
  if (isTreeMetaField(metaField)) {
    return `f-meta-${Number(metaField.id)}_${
      metaField.valueType.includes('multiple') ? 'tree-multiple' : 'tree'
    }`;
  }
  return `f-meta-${Number(metaField.id)}_${language}`;
}

function removeFalsyProperties<T extends {}>(obj: T): Partial<T> {
  const newObject = { ...obj };
  for (const key in newObject) {
    if (!newObject[key]) delete newObject[key];
  }
  return newObject;
}

const Search = ({ inModal, onSearch }: Props) => {
  const criteria = useSelector(state => state.search.criteria);
  const metaFields = useCustomerMetaFields();
  const configById = useSelector(state => state.app.customer?.configById);
  const language = useSelector(state => state.app.settings?.language || 'fi');
  const { updateCriteria: updateAction } = useActions(search.actions);
  const target = useSearchTarget();

  const { data: folderTree } = useGetFolderTreeQuery({
    language,
    type: target ?? 'folder',
    depth: 2,
  });

  // Open toggle for extra search fields
  const [open, setOpen] = useState(false);
  // Activates enter watch when typing in text fields
  const [textFocus, setFocus] = useState(false);
  // Activates other field change monitoring
  const [watchChange, setChange] = useState(false);

  // Enables to pass current focus value to not updating methods
  const focusRef = useRef(textFocus);
  focusRef.current = textFocus;

  // Make sure that the target gets updated
  const updateCriteria = (criteria, ...rest) =>
    updateAction({ ...criteria, target }, ...rest);

  /** This is used just to allow the user to negate a field before entering any value.
   * If the criteria contains a negated value for the field, that should be used as the truth instead
   */
  const [negationFields, setNegationFields] = useState(
    {} as Record<string, boolean>
  );
  const setNegationField = (fieldId: string, negation: boolean) =>
    setNegationFields(old => ({ ...old, [fieldId]: negation }));

  // Enables to pass current criteria value to not updating methods
  const critRef = useRef(criteria);
  critRef.current = criteria;
  // Hook for prev props
  const prevCrit = usePrevious(criteria);

  const criteriaMetaIds = Object.keys(criteria)
    .filter(key => {
      const value = criteria[key];
      return value !== null && (!Array.isArray(value) || value.length > 0);
    })
    .map(str => (str.match(/f-meta-(.*?)_/) ?? [])[1])
    .filter(Boolean);

  const doSearch = (submitSearch?: boolean) => {
    if (!inModal || submitSearch) {
      updateContentCriteria(
        undefined,
        // Sets empty fields to undefined to not include them in search. Empty searches brake the search
        objectMap(parseCriteriaParamsOut(critRef.current), value => {
          return value || undefined;
        }) as SearchCriteria
      );
    }
    if (onSearch && (!inModal || submitSearch)) {
      // Calling onSearch and closing the modal right away, will reopen the modal
      setTimeout(onSearch, 0);
    }
  };

  // Watches search events for enter pressed in text field
  useKeyboardEvent('Enter', () => {
    if (focusRef.current) {
      doSearch();
    }
  });

  // Watches changes in other than text fields
  if (watchChange && prevCrit && criteria !== prevCrit) {
    doSearch();
    setChange(false);
  }

  useEffect(() => {
    if (open) {
      const focusableElements = Array.from(
        document.querySelectorAll<HTMLElement>(
          'textarea.MuiInput-input, div.MuiInput-input, input.MuiInput-input'
        )
      ).filter(el => !el.hasAttribute('disabled'));
      focusableElements[0] && focusableElements[0].focus();
    }

    // Clear empty criteria fields when closing
    if (!open) {
      updateCriteria(removeFalsyProperties(criteria));
    }
  }, [open]);

  const categoryTree = metaFields.find(isTreeMetaField);

  const categoryTreeId =
    categoryTree && getMetaFieldCriteriaId(categoryTree, language);

  const hiddenTypeSelectors: string[] =
    (configById?.['search.materialtypes.hide'] as string)?.split(',') || [];
  const metafieldsStayOnTop: string[] =
    (configById?.['search.metafields.stayOnTop'] as string)?.split(',') || [];

  const categoryTreePosition =
    configById?.['search.tree.position'] ?? TreePosition.FIRST;
  const folderTreePosition =
    configById?.['search.tree.folder.position'] ?? TreePosition.HIDDEN;
  const advancedFieldsPosition =
    configById?.['search.advanced.position'] ?? TreePosition.AFTER;

  const categoryNodeCount =
    (categoryTreeId && criteria[categoryTreeId]?.length) ?? 0;
  const folderNodeCount =
    criteria.tags?.filter(tag => tag.id === ABSOLUTE_FOLDER_TAG_ID).length ?? 0;

  const categoryTreeElement = categoryTree && categoryTreeId && (
    <SearchTreeInput
      title={t`Search tree`}
      onSearch={() => setChange(true)}
      criteriaId={categoryTreeId}
      treeData={categoryTree}
    />
  );
  const folderTreeElement = folderTree && (
    <SearchTreeInput
      title={t`In folder`}
      onSearch={() => setChange(true)}
      criteriaId={ABSOLUTE_FOLDER_TAG_ID} // material that's a direct child of selected folder
      treeData={folderTree}
      useTags
    />
  );

  const getCheckboxValue = (criteriaValue: string | undefined) =>
    criteriaValue !== undefined
      ? (criteriaValue || '').split(',').filter(x => !!x)
      : [];

  const getCheckboxOnChange =
    (key: keyof typeof criteria) => (e: { target: { value: string[] } }) => {
      const { value } = e.target;
      updateCriteria({
        [key]: value.length === 0 ? '' : value.join(','),
      });
      setChange(true);
    };

  const materialTypesOptions = materialTypesSearch()
    .filter(({ value }) => !hiddenTypeSelectors.includes(value))
    .map(option => ({
      ...option,
      disabled:
        !!criteria.mimeGroups ||
        (!!criteria.materialType && option.value !== criteria.materialType),
    }));

  const mimeTypesOptions = mimeGroupsSearch()
    .filter(({ value }) => !hiddenTypeSelectors.includes(value))
    .map(option => ({
      ...option,
      disabled: !!criteria.materialType,
    }));

  const collapseMetaFilters = !configById?.['search.metafilters.show'];

  const stayOnTopMetafields = metaFields.filter(metafield =>
    metafieldsStayOnTop.includes(metafield.id)
  );

  const searchableMetafields = metaFields
    .filter(
      metaField =>
        (metaField.position?.card ?? -1) >= 0 &&
        // Hide the search tree meta field.
        // Maybe this is a good idea :)
        metaField.id !== categoryTree?.id &&
        !metafieldsStayOnTop.includes(metaField.id)
    )
    .sort((a, b) => (a.position?.card ?? 0) - (b.position?.card ?? 0))
    // Attach separators to fields
    .reduce(
      (a, current) => {
        if (current.valueType === 'separator')
          return [...a, { fields: [], separator: current }];
        else
          return [
            ...a.slice(0, a.length - 1),
            {
              fields: [...a[a.length - 1].fields, current],
              separator: a[a.length - 1].separator,
            },
          ];
      },
      [
        {
          fields: [] as MetaField[],
          separator: undefined as MetaField | undefined,
        },
      ]
    );

  const advancedSearchFields = (
    <AdvancedSearchFields
      setChange={setChange}
      setFocus={setFocus}
      getCheckboxOnChange={getCheckboxOnChange}
      getCheckboxValue={getCheckboxValue}
      criteria={criteria}
      updateCriteria={updateCriteria}
    />
  );

  return (
    <Container>
      <FormContainer>
        {target === 'archive' && (
          <Heading renderAs="h2" variant="h2">{t`Search the archive`}</Heading>
        )}
        {/* these will update the criteria to redux state so we will wait for that to change */}
        <SearchfieldPopupWrapper>
          <SearchField onSearch={() => setChange(true)} />
        </SearchfieldPopupWrapper>
        <Tags
          onSearch={beforeSearch => {
            // This is very funky, I know, but we have to start watching the changes first
            // in order to detect the criteria change, prolly should fix this on a more beautiful day...
            setChange(true);
            beforeSearch();
          }}
        />

        <>
          {stayOnTopMetafields.map(field => {
            return (
              field && (
                <MetaFieldInput
                  key={field.id}
                  metaField={field}
                  updateCriteria={updateCriteria}
                  negationFields={negationFields}
                  setNegationField={setNegationField}
                  setChange={setChange}
                  criteria={criteria}
                  setFocus={setFocus}
                  language={language}
                />
              )
            );
          })}
        </>

        {folderTreePosition === TreePosition.FIRST && folderTreeElement}
        {categoryTreePosition === TreePosition.FIRST && categoryTreeElement}
        {advancedFieldsPosition === TreePosition.FIRST && advancedSearchFields}

        {!configById?.['search.materialtypes.disable'] && (
          <>
            <Column padding={0}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <CustomFormLabel label={t`Mime Groups`} compact />
                </FormLabel>
                <MimeGroupContainer>
                  <CheckBoxes
                    options={materialTypesOptions}
                    value={getCheckboxValue(criteria.materialType)}
                    onChange={getCheckboxOnChange('materialType')}
                  />
                  <CheckBoxes
                    options={mimeTypesOptions}
                    value={getCheckboxValue(criteria.mimeGroups)}
                    onChange={getCheckboxOnChange('mimeGroups')}
                  />
                </MimeGroupContainer>
              </FormControl>
              {criteria.mimeGroups === null && (
                <Error>
                  <Trans>Select at least one material type</Trans>
                </Error>
              )}
            </Column>
          </>
        )}

        {folderTreePosition === TreePosition.AFTER && folderTreeElement}
        {categoryTreePosition === TreePosition.AFTER && categoryTreeElement}

        {!open && collapseMetaFilters && (
          <>
            {searchableMetafields
              .flatMap(x => x.fields)
              .filter(field => criteriaMetaIds.includes(field.id))
              .map(field => {
                return (
                  field && (
                    <MetaFieldInput
                      key={field.id}
                      metaField={field}
                      updateCriteria={updateCriteria}
                      negationFields={negationFields}
                      setNegationField={setNegationField}
                      setChange={setChange}
                      criteria={criteria}
                      setFocus={setFocus}
                      language={language}
                    />
                  )
                );
              })}
            {folderTreePosition === TreePosition.MORE &&
              folderNodeCount > 0 &&
              folderTreeElement}
            {categoryTreePosition === TreePosition.MORE &&
              categoryNodeCount > 0 &&
              categoryTreeElement}
          </>
        )}
        {collapseMetaFilters && searchableMetafields && (
          <MetafieldsWrapper
            role="region"
            id="search-meta-fields"
            hidden={!open && collapseMetaFilters}
          >
            {advancedFieldsPosition === TreePosition.AFTER &&
              advancedSearchFields}

            {searchableMetafields
              .flatMap(x => x.fields)
              .map(metafield => (
                <MetaFieldInput
                  key={metafield.id}
                  metaField={metafield}
                  updateCriteria={updateCriteria}
                  negationFields={negationFields}
                  setNegationField={setNegationField}
                  setChange={setChange}
                  criteria={criteria}
                  setFocus={setFocus}
                  language={language}
                />
              ))}
            {folderTreePosition === TreePosition.MORE && folderTreeElement}
            {categoryTreePosition === TreePosition.MORE && categoryTreeElement}
          </MetafieldsWrapper>
        )}
        {!collapseMetaFilters && searchableMetafields && (
          <MetafieldsWrapper>
            {searchableMetafields.map(({ fields, separator }) => (
              <MetaFieldGroup
                key={separator?.id}
                fields={fields}
                criteriaMetaIds={criteriaMetaIds}
                separator={separator}
                updateCriteria={updateCriteria}
                negationFields={negationFields}
                setNegationField={setNegationField}
                setChange={setChange}
                setFocus={setFocus}
                criteria={criteria}
                language={language}
              />
            ))}
          </MetafieldsWrapper>
        )}
      </FormContainer>

      {collapseMetaFilters && (
        <StyledButtonBar>
          <Button
            variant="text"
            color="primary"
            onClick={() => setOpen(!open)}
            aria-controls="search-meta-fields"
            aria-expanded={open}
          >
            {open ? (
              <Trans>Show less filters</Trans>
            ) : (
              <Trans>Show more filters</Trans>
            )}
          </Button>
        </StyledButtonBar>
      )}
    </Container>
  );
};

interface MetaFieldInputProps {
  metaField: MetaField;
  criteria: SearchCriteria;
  updateCriteria: (criteria: Partial<SearchCriteria>) => void;
  negationFields: Record<string, boolean>;
  setNegationField: (fieldId: string, negation: boolean) => void;
  setChange: (value: boolean) => void;
  setFocus: (value: boolean) => void;
  language: string;
}

const MetaFieldInput = ({
  metaField,
  updateCriteria,
  negationFields,
  setNegationField,
  setChange,
  criteria,
  setFocus,
  language,
}: MetaFieldInputProps) => {
  if (metaField.valueType === 'date') {
    let startFieldId = `f-meta-${metaField.id}_dateStart`;
    let endFieldId = `f-meta-${metaField.id}_dateEnd`;
    const negation =
      negationFields[metaField.id] ||
      criteria[`-${startFieldId}`] ||
      criteria[`-${endFieldId}`] !== undefined;
    if (negation) {
      startFieldId = `-${startFieldId}`;
      endFieldId = `-${endFieldId}`;
    }
    return (
      <>
        <FormLabel component="legend">
          <CustomFormLabel label={`${getLangValue(metaField.namesByLang)}`} />
        </FormLabel>
        <StyledRow>
          <StyledColumn key={`start_${metaField.id}`}>
            <FormRow>
              <FormField
                id={`${metaField.id}-${getLangValue(
                  metaField.namesByLang
                )}_dateStart`}
                fieldType={metaField.valueType}
                value={criteria[startFieldId] || null}
                onChange={value => {
                  const obj = {};
                  obj[startFieldId] = value
                    ? format(new Date(value), 'yyyy-MM-dd')
                    : null;
                  updateCriteria(obj);
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`From(range)`}:`}
              />
            </FormRow>
          </StyledColumn>

          <StyledColumn key={`end_${metaField.id}`}>
            <FormRow>
              <FormField
                id={`${metaField.id}-${getLangValue(
                  metaField.namesByLang
                )}_dateEnd`}
                fieldType={metaField.valueType}
                value={criteria[endFieldId] || null}
                onChange={value => {
                  const obj = {};
                  obj[endFieldId] = value
                    ? format(new Date(value), 'yyyy-MM-dd')
                    : null;
                  updateCriteria(obj);
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`To(range)`}:`}
              />
            </FormRow>
          </StyledColumn>

          <MetaOperatorOptions
            criteria={criteria}
            criteriaIds={[startFieldId, endFieldId]}
            metaField={metaField}
            updateCriteria={updateCriteria}
            setNegationField={setNegationField}
            setChange={setChange}
            negation={negation}
          />
        </StyledRow>
      </>
    );
  } else {
    let criteriaId = getMetaFieldCriteriaId(metaField, language) as string;
    const negation =
      negationFields[metaField.id] || criteria[`-${criteriaId}`] !== undefined;
    if (negation) criteriaId = `-${criteriaId}`;
    const isDropdown = isDropdownMetaField(metaField);
    return (
      <Column key={metaField.id}>
        <FormRow>
          <MetaFieldRow>
            <FormField
              id={`${metaField.id}-${getLangValue(metaField.namesByLang)}`}
              onFocus={() => setFocus(true)}
              fullWidth
              variant="standard"
              fieldType={
                metaField.valueType === 'wysiwyg'
                  ? 'text'
                  : metaField.autocomplete
                  ? 'autocomplete'
                  : metaField.valueType
              }
              value={criteria[criteriaId] ?? ''}
              onChange={value => {
                updateCriteria({
                  [criteriaId]: value,
                });
                if (isDropdown) setChange(true);
              }}
              onBlur={() => {
                setFocus(false);
                if (!criteria[criteriaId]) {
                  updateCriteria({ [criteriaId]: undefined });
                }
              }}
              options={
                isDropdown
                  ? getLangValue(metaField.optionsByLang).map(val => ({
                      name: val,
                      value: val,
                    }))
                  : undefined
              }
              optionsLang={
                isDropdown
                  ? getContentLanguage(metaField.optionsByLang)
                  : undefined
              }
              labelText={getLocalizedValue(metaField.namesByLang)}
              autoComplete="on"
              allowClearing
              onClear={() => {
                setNegationField(metaField.id, false);
                updateCriteria({ [criteriaId]: undefined });
                setChange(true);
              }}
              metaFieldId={metaField.id}
            />

            <MetaOperatorOptions
              criteria={criteria}
              criteriaIds={[criteriaId]}
              metaField={metaField}
              updateCriteria={updateCriteria}
              setNegationField={setNegationField}
              setChange={setChange}
              negation={negation}
            />
          </MetaFieldRow>
        </FormRow>
      </Column>
    );
  }
};

const MetaFieldRow = styled.div`
  display: flex;
  & :first-child {
    flex: 1;
  }
`;

function MetaOperatorOptions({
  metaField,
  criteria,
  criteriaIds,
  updateCriteria,
  setNegationField,
  setChange,
  negation,
}: Pick<
  MetaFieldInputProps,
  'metaField' | 'criteria' | 'updateCriteria' | 'setNegationField' | 'setChange'
> & { criteriaIds: string[]; negation: boolean }) {
  return (
    <Tooltip title={negation ? t`Not` : ''} placement="top">
      <MetaOperatorsButton
        icon={
          <Badge color="primary" variant="dot" invisible={!negation}>
            <MoreVertIcon fontSize="small" />
          </Badge>
        }
        options={[
          {
            value: 'not',
            title: t`Not`,
            selected: negation,
            onSelect: () => {
              setNegationField(metaField.id, !negation);
              updateCriteria(
                criteriaIds.reduce(
                  (acc, criteriaId) => ({
                    ...acc,
                    [criteriaId]: undefined,
                    [negation ? criteriaId.substring(1) : `-${criteriaId}`]:
                      criteria[criteriaId] ?? null,
                  }),
                  {}
                )
              );
              setChange(true);
            },
          },
        ]}
        size="small"
      />
    </Tooltip>
  );
}

const MetaOperatorsButton = styled(MenuButton)`
  align-self: center;
  margin-right: -10px;
`;

type MetaFieldGroupProps = {
  fields: MetaField[];
  separator?: MetaField;
  criteriaMetaIds: string[];
} & Pick<
  MetaFieldInputProps,
  | 'updateCriteria'
  | 'negationFields'
  | 'setNegationField'
  | 'setChange'
  | 'criteria'
  | 'setFocus'
  | 'language'
>;

const MetaFieldGroup = ({
  fields,
  separator,
  criteriaMetaIds,
  ...inputProps
}: MetaFieldGroupProps) => {
  const configById = useSelector(state => state.app.customer?.configById);
  const isGroupOpen = !!configById?.['search.metafilters.groups.open'] ?? false;
  const [open, setOpen] = useState(isGroupOpen);

  if (!separator)
    return (
      <>
        {fields.map(metafield => (
          <MetaFieldInput
            key={metafield.id}
            metaField={metafield}
            {...inputProps}
          />
        ))}
      </>
    );

  const separatorName = getLangValue(separator.namesByLang);
  const showSeparator =
    !!configById?.['search.metafilters.separators.show'] ?? true;

  if (!showSeparator)
    return (
      <>
        {fields.map(metafield => (
          <MetaFieldInput
            key={metafield.id}
            metaField={metafield}
            {...inputProps}
          />
        ))}
      </>
    );

  return (
    <>
      <SimpleAccordion expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {separatorName}
        </AccordionSummary>
        <AccordionDetails>
          {fields.map(metafield => (
            <MetaFieldInput
              key={metafield.id}
              metaField={metafield}
              {...inputProps}
            />
          ))}
        </AccordionDetails>
      </SimpleAccordion>
      {!open &&
        fields
          .filter(field => criteriaMetaIds.includes(field.id))
          .map(metafield => (
            <MetaFieldInput
              key={metafield.id}
              metaField={metafield}
              {...inputProps}
            />
          ))}
    </>
  );
};

interface AdvancedFieldsProps {
  getCheckboxValue: (value: string | undefined) => string[];
  getCheckboxOnChange: any;
  criteria: SearchCriteria;
  updateCriteria: (criteria: Partial<SearchCriteria>) => void;
  setChange: (value: boolean) => void;
  setFocus: (value: boolean) => void;
}

const AdvancedSearchFields: React.FC<AdvancedFieldsProps> = ({
  criteria,
  getCheckboxValue,
  getCheckboxOnChange,
  updateCriteria,
  setFocus,
  setChange,
}) => {
  return (
    <>
      <Column padding={0}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            <CustomFormLabel label={t`Entry type`} />
          </FormLabel>
          <MimeGroupContainer>
            <CheckBoxes
              options={[
                {
                  name: t`Concrete`,
                  value: 'concrete',
                  disabled: criteria.entryType === 'link',
                },
                {
                  name: t`Link`,
                  value: 'link',
                  disabled: criteria.entryType === 'concrete',
                },
              ]}
              value={getCheckboxValue(criteria.entryType)}
              onChange={getCheckboxOnChange('entryType')}
            />
          </MimeGroupContainer>
        </FormControl>
      </Column>

      <MetafieldsWrapper>
        <FormField
          id={'createdby'}
          onFocus={() => setFocus(true)}
          fullWidth
          variant="standard"
          fieldType={'text'}
          onChange={username => {
            updateCriteria({
              createdBy: username && encodeURIComponent(username),
            });
          }}
          value={
            criteria.createdBy ? decodeURIComponent(criteria.createdBy) : ''
          }
          onBlur={() => setFocus(false)}
          labelText={t`Created by`}
          autoComplete="on"
          allowClearing
          onClear={() => {
            updateCriteria({ createdBy: '' });
            setChange(true);
          }}
        />

        <FormControl component="fieldset">
          <FormLabel component="legend">
            <CustomFormLabel label={t`Created`} />
          </FormLabel>
          <StyledRow>
            <StyledColumn>
              <FormField
                id={`createdStart`}
                fieldType={'date'}
                value={criteria.createdStart || null}
                onChange={value => {
                  updateCriteria({
                    createdStart:
                      value && format(new Date(value), 'yyyy-MM-dd'),
                  });
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`From(range)`}:`}
              />
            </StyledColumn>

            <StyledColumn>
              <FormField
                id={`createdEnd`}
                fieldType={'date'}
                value={criteria.createdEnd || null}
                onChange={value => {
                  updateCriteria({
                    createdEnd: value && format(new Date(value), 'yyyy-MM-dd'),
                  });
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`To(range)`}:`}
              />
            </StyledColumn>
          </StyledRow>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">
            <CustomFormLabel label={t`Modified`} />
          </FormLabel>
          <StyledRow>
            <StyledColumn>
              <FormField
                id={`modifiedStart`}
                fieldType={'date'}
                value={criteria.modifiedStart || null}
                onChange={value => {
                  updateCriteria({
                    modifiedStart:
                      value && format(new Date(value), 'yyyy-MM-dd'),
                  });
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`From(range)`}:`}
              />
            </StyledColumn>

            <StyledColumn>
              <FormField
                id={`modifiedEnd`}
                fieldType={'date'}
                value={criteria.modifiedEnd || null}
                onChange={value => {
                  updateCriteria({
                    modifiedEnd: value && format(new Date(value), 'yyyy-MM-dd'),
                  });
                  setChange(true);
                }}
                reverseAdornments
                labelText={`${t`To(range)`}:`}
              />
            </StyledColumn>
          </StyledRow>
        </FormControl>

        <FormControl component="fieldset">
          <FormLabel component="legend">
            <CustomFormLabel label={t`File size (Mt)`} />
          </FormLabel>
          <StyledRow>
            <StyledColumn>
              <FormField
                allowClearing
                onClear={() => {
                  updateCriteria({ fileSizeMin: NaN });
                  setChange(true);
                }}
                id={`fileSizeMin`}
                fieldType={'numeric'}
                value={criteria.fileSizeMin}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={megaBytes => {
                  updateCriteria({
                    fileSizeMin: parseFloat(megaBytes),
                  });
                }}
              />
            </StyledColumn>
            -
            <StyledColumn>
              <FormField
                allowClearing
                onClear={() => {
                  updateCriteria({ fileSizeMax: NaN });
                  setChange(true);
                }}
                id={`fileSizeMax`}
                fieldType={'numeric'}
                value={criteria.fileSizeMax}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(megaBytes: string) => {
                  updateCriteria({
                    fileSizeMax: parseFloat(megaBytes),
                  });
                }}
              />
            </StyledColumn>
          </StyledRow>
        </FormControl>
      </MetafieldsWrapper>
    </>
  );
};

const Container = styled.div`
  max-height: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr min-content;

  & > * {
    min-height: 0;
  }
`;

const Heading = styled(Typography)`
  margin-bottom: -${p => p.theme.spacing(3)};
  align-self: flex-start;
` as typeof Typography;

const FormContainer = styled.form`
  padding: ${p => p.theme.spacing(2)};
  padding-top: 10px; /* Align with the select all checkbox */
  overflow-y: auto;

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

const SearchfieldPopupWrapper = styled.div``;

const MetafieldsWrapper = styled.div`
  padding-top: ${p => p.theme.spacing(2)};
`;

const StyledButtonBar = styled(ButtonBar)`
  margin-top: 0;
  background: white;
  @media screen and (min-width: 1300px) {
    button {
      width: 100% !important;
    }
  }
`;

const MimeGroupContainer = styled.div`
  /* Align with the rest of the fields */
  margin-left: 11px;
`;

const StyledRow = styled.div`
  display: flex;
  gap: ${p => p.theme.spacing(1)};
`;

const StyledColumn = styled(Column)`
  width: 50%;
`;

const SimpleAccordion = styled(Accordion).attrs({ disableGutters: true })`
  border: none;
  background: transparent;

  &::before {
    content: none;
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding-inline: 0;
  }
`;

export default Search;
