import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '~utils/api.utils';

export type BaseQueryFn = ReturnType<typeof axiosBaseQuery>;
export type TagTypes = typeof tagTypes[number];

const tagTypes = [
  // used in /src/settings/layout/api.ts
  'SettingsData',

  // used in /src/settings/api.ts
  'UserGroups',

  // used in /src/settings/rights/api.ts
  'ObjectRights',

  // Used in /src/common/user.api.ts
  'User',

  // used in /src/content/workspaces/api.ts
  'WorkspaceTree',
  'DefaultWorkspace',

  // used in /src/content/shopping/api.ts
  'ShoppingCart',

  // used in /src/common/content.api.ts
  'File',
  'FolderTree',
  'ArchiveTree',
  'MetaFields',
  'Version',

  // used in /src/common/product.api.ts
  'Product',
  'MassImport',

  // used in /src/common/workflow/api.ts
  'Workflow',

  // used in /src/orders/api.ts
  'Order',

  // used in /src/contents/uploadForms/api.ts
  'UploadForm',
  'UploadFormList',

  'UploadFormInvite',
  'UploadFormInviteList',

  'GroupsByUsers',

  // used in /src/contents/archive/api.ts
  'ArchiveSchedule',
] as const;

// initialize an empty api service that we'll inject endpoints into later
// as needed
export const apiBase = createApi({
  baseQuery: axiosBaseQuery(),
  tagTypes,
  endpoints: () => ({}),
});
