import styled, { css } from 'styled-components';

import { SwapVert, ArrowRightAlt, Build } from '@mui/icons-material';

export { default as CheckboxCheckedIcon } from '../icons/CheckedIcon';
export { default as CheckboxUncheckedIcon } from '../icons/UncheckedIcon';
export { default as CollapsedFolderIcon } from '../icons/FilledPlus';
export { default as CollapsedFolderIconOutlined } from '../icons/CollapsedIcon';
export { default as ExpandedFolderIcon } from '../icons/ExpandedIcon';
export { default as ExpandedFolderIconOutlined } from '../icons/OutlinedMinus';
export { default as MoveToFolderIcon } from '../icons/MoveToFolderIcon';
export { default as FoldersIcon } from '../icons/HierarchyIcon';
export { default as FolderContentIcon } from '../icons/FolderContent';
export { default as CommentingIcon } from '../icons/CommentingIcon';
export { default as AddMaterialIcon } from '../icons/AddMaterialIcon';
export { default as SuperscriptIcon } from '../icons/SuperscriptIcon';
export { default as SubscriptIcon } from '../icons/SubscriptIcon';
export { default as SynkkaIcon } from '../icons/SynkkaIcon';
export { default as ExcelIcon } from '../icons/ExcelIcon';
export {
  // Actions
  Add as AddIcon,
  AddCircleOutline as AddCircleIcon,
  ArchiveOutlined as ArchiveIcon,
  UnarchiveOutlined as UnarchiveIcon,
  ArrowBack as BackIcon,
  CheckCircleOutlined as CheckCircleIcon,
  Check as CheckIcon,
  CropFree as CropIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  GetApp as DownloadIcon,
  ExitToApp as LogoutIcon,
  Print as PrintIcon,
  OpenInBrowser as OpenIcon,
  OpenInNew as OpenInNewIcon,
  AspectRatio as ResizeIcon,
  Search as SearchIcon,
  Save as SaveIcon,
  Send as SendIcon,
  Send as OrderIcon,
  Share as ShareIcon,
  VolumeUp as ListenIcon,
  Loop as ReloadIcon,
  // Items
  Image as ImageIcon,
  FileCopy as FileIcon,
  FolderOpen as FolderIcon,
  FolderOpen as FolderOpenIcon,
  Wallpaper as ProductIcon,
  TextFields as TextFieldsIcon,
  // Navigation
  Home as HomeIcon,
  Person as UserIcon,
  Settings as SettingsIcon,
  ShoppingCart as WorkspaceIcon,
  AddShoppingCart as AddWorkspaceIcon,
  Close as CloseIcon,
  ArrowDropDown as DropDownIcon,
  Link as LinkIcon,
  Menu as MenuIcon,
  MoreHoriz as MoreHorizIcon,
  MoreVert as MoreVertIcon,
  ArrowUpward as ParentItemIcon,
  ArrowForward as ArrowForwardIcon,
  // Paging
  FirstPage as FirstPageIcon,
  LastPage as LastPageIcon,
  NavigateBefore as PrevPageIcon,
  NavigateNext as NextPageIcon,
  NavigateBefore as PrevItemIcon,
  NavigateNext as NextItemIcon,
  // Sorting
  Sort as SortIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  SwapHoriz as SwitchIcon,
  // Product element actions
  Layers as ElementsIcon,
  FormatBold as BoldIcon,
  FormatItalic as ItalicIcon,
  FormatUnderlined as UnderlineIcon,
  FormatColorText as FontColorIcon,
  FormatColorFill as FillColorIcon,
  FormatAlignLeft as HAlignLeftIcon,
  FormatAlignRight as HAlignRightIcon,
  FormatAlignCenter as HAlignCenterIcon,
  FormatAlignJustify as HAlignJustifyIcon,
  VerticalAlignTop as VAlignTopIcon,
  VerticalAlignCenter as VAlignCenterIcon,
  VerticalAlignBottom as VAlignBottomIcon,
  FormatLineSpacing as LineHeightIcon,
  LineWeight as LineWidthIcon,
  BorderColor as LineColorIcon,
  ArrowDropDown as ArrowDropDownIcon,
  Undo as UndoIcon,
  Redo as RedoIcon,
  FormatListBulleted as UnorderedListIcon,
  FormatListNumbered as OrderedListIcon,
  // Zooming
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ZoomOutMap as ZoomResetIcon,
  // Comments
  DoneAll as ApproveIcon,
  AddBoxOutlined as RectangleCommentIcon,
  AddCircleOutline as RoundCommentIcon,
  AddCommentOutlined as AddCommentIcon,
  ModeCommentOutlined as CommentIcon,
  Event as CalendarIcon,
  EventBusy as TerminateIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Loop as NewCommentRoundIcon,
  // Misc
  AccessTime as ClockIcon,
  Subtitles as SubtitlesIcon,
  Cancel as CancelIcon,
  Info as InfoIcon,
  GridOn as GridIcon,
  RestorePage as VersionIcon,
  LibraryAddCheck as RightsIcon,
  Visibility as RevealIcon,
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Restore as RestoreIcon,
  ShoppingBasket as MagentoIcon,
  ShoppingBasket as ShoppingCartIcon,
  Password as PasswordIcon,
  PlayArrow as PlayIcon,
  FavoriteBorder as LikeIcon,
  Favorite as LikedIcon,
} from '@mui/icons-material';

const IconStyles = css`
  color: ${p => p.theme.palette.grey['500']};
`;

export const ArrowDownIcon = styled(ArrowRightAlt)`
  transform: rotate(90deg);
  ${IconStyles}
`;

export const ArrowUpIcon = styled(ArrowRightAlt)`
  transform: rotate(-90deg);
  ${IconStyles}
`;

export const SortableIcon = styled(SwapVert)`
  ${IconStyles}
`;

export const ActionIcon = styled(Build)`
  color: #ffffff;
`;
