import { PayloadAction } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { parseCriteriaParamsIn } from './utils';
import { parseTags, uniqueBy } from '../common/utils';
import { MaterialType } from '~common/common.types';
import { SearchTag } from '~common/content.types';
import { entryTypeSearch } from '~common/content.constants';
import { createSlice } from '~common/utils/ducks.utils';

export type EntryType = ReturnType<typeof entryTypeSearch>[number]['value'];

export type SearchCriteria = {
  createdStart?: string;
  createdEnd?: string;
  modifiedStart?: string;
  modifiedEnd?: string;
  fileSizeMin?: number;
  fileSizeMax?: number;
  createdBy?: string;
  search?: string;
  tags?: SearchTag[];
  materialType?: MaterialType;
  entryType?: EntryType;
  target?: 'archive';
  // Home page pinned materials
  showOnFrontpage?: 'true' | 'false';
  linkedId?: string;
  mimeGroups?: string;
  [key: `f-meta-${number}_${string}` | `-f-meta-${number}_${string}`]:
    | string
    | undefined;
};

export interface SearchState {
  criteria: SearchCriteria;
  tags: SearchTag[];
  categoryTitles: Record<string, string>;
  selectedTreeNodes: Set<string>;
}

const initialState: SearchState = {
  criteria: {
    tags: [],
  },
  tags: [],
  categoryTitles: {},
  selectedTreeNodes: new Set(),
};

const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    clear: state => {
      state.criteria = initialState.criteria;
      state.selectedTreeNodes = new Set();
    },

    updateCriteria: {
      prepare: (criteria: SearchCriteria, discardOld?: boolean) => ({
        payload: {
          criteria,
          discardOld,
        },
      }),
      reducer: (
        state,
        {
          payload: {
            criteria: { tags, ...criteria },
            discardOld,
          },
        }: PayloadAction<{ criteria: SearchCriteria; discardOld?: boolean }>
      ) => {
        const obj = {
          ...(discardOld ? {} : state.criteria),
          ...parseCriteriaParamsIn(criteria),
        };
        // Filter out fields that are `undefined`
        const newCriteria = Object.keys(obj).reduce(
          (newObj, key) =>
            obj[key] !== undefined ? { ...newObj, [key]: obj[key] } : newObj,
          {} as Record<string, any>
        );

        const parsedTags = tags
          ? typeof tags === 'string'
            ? parseTags(tags)
            : tags
          : state.tags;
        const newTags = uniqueBy(parsedTags, getTagUniqueId).filter(
          tag => tag.value
        );

        state.criteria = { ...newCriteria, tags: newTags || undefined };
        // Pass tags from url to the correct store field
        state.tags = newTags;
      },
    },

    updateTags: (state, { payload }: PayloadAction<SearchTag[]>) => {
      state.tags = uniqueBy([...state.tags, ...payload], getTagUniqueId).filter(
        tag => tag.value
      );
    },

    updateCategoryTitles: (
      state,
      action: PayloadAction<Record<string, string>>
    ) => {
      merge(state, { categoryTitles: action.payload });
    },

    setSelectedNodes: (
      state,
      action: PayloadAction<string[] | Set<string>>
    ) => {
      state.selectedTreeNodes = new Set(action.payload);
    },
  },
});

const getTagUniqueId = (tag: SearchTag) => `${tag.id}-${tag.value}`;

export default slice.reducer;

// Bundle things in a model
export const search = {
  actions: slice.actions,
  selector: {},
};
